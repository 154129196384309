import { CellularSettings } from '../apn/cellular-settings';
import { Taggable } from '../core/core';
import { GasSetting } from '../gas/gas';
import { HazardLevel } from '../safety-observations/safety-observation';
import { i18nLocale } from '../translate/translate';
import { WranglerResponse } from '../wranglers/wrangler';


export interface Beacon {
    id: string;
    name: string;
}

export interface FacilityPerson {
    personId: string;
    facilityId: string;
    group: string;
}

export interface AddFacilityPersonRequest {
    personId: string;
    facilityId: string;
    group: string;
}

export interface Facility extends Omit<Taggable, 'facilityId'> {
    id?: string;
    name: string;
    address: string;
    location: number[];
    bounds: number[][];
    zoom: number;
    image?: {
        uri?: string;
        placement?: {
            bounds: number[][];
            rotation: number;
            vertices: number[][];
        };
    };
    timezone?: string;
    people?: FacilityPerson[];
    settings?: FacilitySettings;
    waltSettings?: WaltSettings;
    trackingCircles?: Array<{
        latitude: number;
        longitude: number;
        radius: number;
    }>;
    updated?: Date;
}

export interface FacilityResponse extends Facility {
    accountId?: string;
    beacons?: Beacon[];
    wranglers: WranglerResponse[];
}

export interface FacilitySettings {
    gases: GasSetting[];
    readingsInterval: number;
    locationTimeoutHrs?: number;
    safetyObservation?: {
        categories?: Array<{
            name: string;
            hazardLevel: HazardLevel;
            readOnly: boolean;
        }>;
        resolutionTypes?: string[];
    };
    peer?: {
        enterDistance?: number[];
        enterTime?: number;
        exitDistance?: number[];
        exitTime?: number;
    };
    beacon?: {
        enterDistance?: number[];
        enterTime?: number;
        exitDistance?: number[];
        nearestBuffer?: number;
        exitTime?: number;
    };
    leaving?: {
        enabled?: boolean;
        title?: string;
        body?: string;
    };
    music?: {
        enabled?: boolean;
        headphonesRequired?: boolean;
        maxVolume?: number;
        bannedGeofenceIds?: string[];
    };
    walt?: {
        minWifiRssi?: number;
        minWifiLteDifference?: number;
    };
    excludeCompanies?: string[];
}

export interface FacilityControlCenterResponse extends Facility {
    createdBy?: string; // used to send to CC when created.
    accountId?: string; // used to send to CC when created.
}

export type UpdateSettingsRequest = Partial<FacilitySettings>;

export interface Simulator {
    personCount?: number;
    itemCount?: number;
    running?: boolean;
    error?: string;
    enabled?: Date;
    disabled?: Date;
}

export interface WeavixFacilityRequest {
    name: string;
    address: string;
    location: number[];
    bounds?: number[][];
    locale: i18nLocale;
    overrideWaltOta?: string;
    overrideWaltApk?: string;
    waltAccessories?: WaltAccessoryType[];
}

export interface FacilityChangesResponse {
    added: FacilityControlCenterResponse[];
    updated: FacilityControlCenterResponse[];
    deleted: FacilityControlCenterResponse[];
    lastDate: Date;
    lastId: string;
}

/**
 * Site-level settings that apply to all walts at the site.
 */
export interface WaltSettings {
    /** Number of seconds of no movement until walt requires PIN to unlock  */
    stationaryTime: number;

    /** If true, users must log in within undockAlarmTime seconds or else the walt will go into alarm */
    undockAlarmEnabled: boolean;

    /** After undocking, if a user has not logged in within this many seconds, then the walt sounds a continuous alarm (if undockAlarmEnabled is true) */
    undockAlarmTime: number;

    /** Info about how to automatically connect to the wifi on this site */
    wifiCredentials: WifiCredential[];

    /** APN settings for Private Cellular SIMs */
    cellularSettings?: CellularSettings[];

    /** The time of day (seconds past 00:00:00) walts should automatically update if a new mandatory version is available */
    startUpdateTime?: number;

    /**
     * A url-encoded connection string used to generate a QR code.
     * A walt can scan this QR to retrieve wifi/cellular connection information if it cannot connect to the internet.
     * If it's already connected to the internet, then it will automatically retrieve new wifi/cellular connection information
     * from the wifiCredentials/cellularSettings fields.
     */
    connectCredentialsQr?: string;

    /**
     * array of accesssories on the facility
     * Only these options will be "detected" by walts assigned to this facility
     * @example ['VX400', 'speaker-mic', 'adapter']
     */
    accessories?: WaltAccessoryType[];

    // Flag to override the mandatory walt version for the site to something newer
    overrideWaltOta?: string;
    overrideWaltApk?: string;
}

export enum WifiAuthenticationType {
    Protected = 'protected',
    Unprotected = 'unprotected',
}

export interface WifiCredential {
    type: WifiAuthenticationType;
    ssid: string;
    password: string;
}

export enum WaltAccessoryType {
    SpeakerMic = 'speaker-mic',
    Adapter = 'adapter',
    /**
     * Old one ear headset
     */
    VX400 = 'VX400',
    /**
     * New, louder one ear headset
     */
    VX400B = 'VX400-B',
}
