import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableComponent } from 'app/components/table/table.component';
import { Instance } from 'app/models/control-center/instance.model';
import { FeatureFlag } from '@weavix/models/src/features/feature-flag';
import { Feature } from '@weavix/models/src/features/feature';
import { HttpService } from 'app/services/http.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Site } from 'app/models/control-center/site.model';

type DisplayFeatureFlag = {
    id: string;
    name: string;
    description: string;
    isUsingDefault: boolean;
    enabledCount: number;
    disabledCount: number;
    usingDefault: boolean;
    defaultValue: boolean;
};

@Component({
    selector: 'app-instance-feature-flags',
    standalone: false,
    templateUrl: './instance-feature-flags.component.html',
    styleUrl: './instance-feature-flags.component.scss',
})
export class InstanceFeatureFlagsComponent implements OnInit {
    @Input() instance!: Instance;
    @ViewChild(TableComponent) featureFlagsTable: TableComponent;
    @ViewChild('updateDialog') updateDialog: TemplateRef<unknown>;

    loading = true;
    // the display flags used to populate the table.  display flags combine features and feature flags
    dataSource: DisplayFeatureFlag[] = [];
    // map of display feature flags by id, a reflection of the array used to populate the table
    displayFlagsMap = new Map<string, DisplayFeatureFlag>();
    // list of feature flags for the current company, used for reference
    featureFlags: FeatureFlag[] = [];
    // list of features for the current company, used for reference
    features: Feature[] = [];
    // list of sites for the current company, used for reference
    sites: Site[] = [];
    // the currently selected feature, set when a row is clicked
    selectedFeature: DisplayFeatureFlag | null = null;
    // dictates the if the selected feature is enabled or disabled for each site
    siteEnablement: { [key: string]: boolean } = {};
    // dictates the value of the "all sites" checkbox
    allSitesCheckboxValue = undefined;

    displayedColumns: any = [
        {
            key: 'name',
            label: 'Feature',
            type: 'string',
            value: (row: DisplayFeatureFlag) => row?.name,
        },
        {
            key: 'id',
            label: 'Id',
            type: 'string',
            value: (row: DisplayFeatureFlag) => row?.id,
        },
        {
            key: 'description',
            label: 'Description',
            type: 'string',
            value: (row: DisplayFeatureFlag) => row?.description,
        },
        {
            key: 'enabledFor',
            label: 'Enabled For Sites',
            type: 'string',
            value: (row: DisplayFeatureFlag) => row?.usingDefault ? `Using default (${row?.defaultValue})` : `Enabled: ${row?.enabledCount}, Disabled: ${row?.disabledCount}`,
        },
    ];

    constructor(private httpService: HttpService, private dialog: MatDialog) {
    }

    async ngOnInit() {
        try {
            this.loading = true;
            [this.features, this.featureFlags] = await Promise.all([this.loadFeatures(), this.loadFeatureFlags()]);
            this.sites = await this.httpService.get(`companies/${this.instance.companyId}/sites`);
            this.sites = this.sites.filter(x => x.weavixSiteId && x.instanceId === this.instance.id);
            this.dataSource = this.buildTableData(this.features, this.featureFlags);
            this.displayFlagsMap = this.features.reduce((map, obj) => map.set(obj.id, obj), new Map());
        } catch (e) {
            alert('Failed to load feature flags');
            console.error('Failed to load feature flags', e);
        } finally {
            this.loading = false;
        }
    }

    async loadFeatures() {
        try {
            return await this.httpService.get(`companies/weavix-account/features`);
        } catch (e) {
            console.error('Failed to load features', e);
            return new Map<string, Feature>();
        }
    }

    async loadFeatureFlags() {
        try {
            if (!this.instance?.weavixAccountId) throw new Error('No instance provided');
            return await this.httpService.get(`companies/weavix-account/${this.instance.weavixAccountId}/feature-flags`);
        } catch (e) {
            console.error('Failed to load feature flags', e);
            return [];
        }
    }

    buildTableData(features: Feature[] = [], featureFlags: FeatureFlag[] = []): DisplayFeatureFlag[] {
        return features.map((feature) => {
            const ff = featureFlags.filter(x => x.id.featureId === feature.id && x.enabled !== undefined);
            const getEnabledCount = () => {
                // if there are no feature flags, use the default value
                if (ff.length === 0) return feature.defaultValue ? this.sites.length : 0;

                // if the feature is enabled or disabled at the account level, that overrides everything.
                const accountSetting = ff.find(x => x.id.accountId && !x.id.facilityId);
                if (accountSetting) {
                    const settingValue = accountSetting.enabled !== undefined ? accountSetting.enabled : feature.defaultValue;
                    return settingValue ? this.sites.length : 0;
                }

                return ff.filter(x => x.enabled).length;
            };

            return {
                id: feature.id,
                name: feature.name,
                description: feature.description,
                isUsingDefault: ff.length === 0,
                enabledCount: getEnabledCount(),
                disabledCount: ff.length > 0 ? this.sites.length - getEnabledCount() : 0,
                usingDefault: ff.length === 0,
                defaultValue: feature.defaultValue,
            };
        });
    }

    public rowClick(event) {
        this.selectedFeature = this.displayFlagsMap.get(event);
        const existingAccountSetting = this.featureFlags.find(x => !x.id.facilityId && this.selectedFeature.id === x.id.featureId);
        if (existingAccountSetting) {
            this.allSitesCheckboxValue = existingAccountSetting.enabled !== undefined ? existingAccountSetting.enabled : this.selectedFeature.defaultValue;
        }

        this.siteEnablement = {};
        this.sites.forEach(site => {
            this.siteEnablement[site.weavixSiteId] = this.featureFlags.find(x => x.id.facilityId === site.weavixSiteId && x.id.featureId === this.selectedFeature.id)?.enabled ?? this.selectedFeature.defaultValue;
        });

        this.dialog.open(this.updateDialog);
    }

    public async save() {
        try {
            this.loading = true;
            const newFeatureFlags: FeatureFlag[] = [];
            // if all the sites are enabled or disabled, we can set the account level setting
            if (this.allSitesCheckboxValue !== undefined) {
                newFeatureFlags.push({
                    id: {
                        featureId: this.selectedFeature.id,
                        accountId: this.instance.weavixAccountId,
                    },
                    enabled: this.allSitesCheckboxValue,
                    lastUpdated: new Date(),
                });
                // otherwise, explicitly set the feature flag for each site
            } else {
                this.sites.forEach(site => {
                    newFeatureFlags.push({
                        id: {
                            featureId: this.selectedFeature.id,
                            accountId: this.instance.weavixAccountId,
                            facilityId: site.weavixSiteId,
                        },
                        enabled: this.siteEnablement[site.weavixSiteId],
                        lastUpdated: new Date(),
                    });
                });
            }

            await this.httpService.put(`companies/weavix-account/${this.instance.weavixAccountId}/feature-flags/${this.selectedFeature.id}`, { ccAccountId: this.instance.id, featureFlags: newFeatureFlags });
            this.dialog.closeAll();
            await this.ngOnInit();
        } catch (e) {
            alert('Failed to update feature flags');
            console.error('Failed to update feature flags', e);
        } finally {
            this.loading = false;
        }

    }

    public async useDefaultFeatureValue() {
        try {
            this.loading = true;
            const newFeatureFlags: FeatureFlag[] = [{
                id: {
                    featureId: this.selectedFeature.id,
                    accountId: this.instance.weavixAccountId,
                },
                enabled: undefined, // this means "use default"
                lastUpdated: new Date(),
            }];
            await this.httpService.put(`companies/weavix-account/${this.instance.weavixAccountId}/feature-flags/${this.selectedFeature.id}`, { ccAccountId: this.instance.id, featureFlags: newFeatureFlags });
            this.dialog.closeAll();
            await this.ngOnInit();
        } catch (e) {
            alert('Failed to use default');
            console.error('Failed to use default', e);
        } finally {
            this.loading = false;
        }
    }

    public toggleAllSites() {
        this.sites.forEach(site => {
            this.siteEnablement[site.weavixSiteId] = this.allSitesCheckboxValue;
        });
    }

    public onToggleIndividualSite() {
        this.allSitesCheckboxValue = undefined;
    }
}
