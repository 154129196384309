import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Instance } from 'app/models/control-center/instance.model';
import { Person } from 'app/models/control-center/person.model';
import { Walt } from 'app/models/control-center/walt.model';
import { HttpService } from 'app/services/http.service';
import { min, uniq } from 'lodash';
import { AddressSearchComponent } from 'app/components/addressSearch/address-search.component';
import { Utils } from 'app/utils';
import { Language, Site, HeadsetAccessory } from '../../models/control-center/site.model';
import { WaltAccessoryType } from '@weavix/models/src/facility/facility';
import { VersionData } from '@weavix/models/src/api/version-response';
import { isVersionNewer } from '@weavix/domain/src/utils/is-version-newer';

interface WaltWifiInfo {
    totalCount: number;
    enabledCount: number;
    disabledCount: number;
}

@Component({
    selector: 'app-site',
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit {

    constructor(
        private _rotue: ActivatedRoute,
        private _httpService: HttpService,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _snackBar: MatSnackBar,
    ) { }

    @ViewChild(AddressSearchComponent) addressSearch: AddressSearchComponent;

    loading = true;
    saving = false;
    site: Site = new Site();
    sites: Site[];
    walts: Walt[];
    waltVersions: string[];
    waltOta: VersionData;
    waltApk: VersionData;
    currentWaltVersion: string;

    people: Person[] = [];
    permissions: Permissions;
    instances: Instance[] = [];
    languages: Language[] = [];
    headsetAccessories: HeadsetAccessory[] = [];

    siteForm: FormGroup;

    waltWifiInfo: WaltWifiInfo;

    waltWifiDisabledDate: Date;

    shouldWaltWifisDisable = false;
    isEnableDisableWaltWifisModalOpen = false;
    isEnablingDisablingWaltWifis = false;

    isDeleteSavedWifiModalOpen = false;
    isDeletingSavedWifis = false;

    selectedWaltAccessories: WaltAccessoryType[];

    get waltWifiDisabledDateFormatted() {
        return this.waltWifiDisabledDate ? Utils.formatDate(this.waltWifiDisabledDate.valueOf()) : null;
    }

    async ngOnInit() {

        this.loading = true;

        this.site.id = Number(this._rotue.snapshot.paramMap.get('id'));
        const companyId = Number(this._rotue.snapshot.queryParamMap.get('companyId'));
        if (companyId) this.site.companyId = companyId;

        if (this.site.id !== 0) {
            this.site = await this._httpService.get(`companies/sites/${this.site.id}`);
        }

        await Promise.all([
            (async () => this.permissions = await this._httpService.get(`people/authenticated/permissions`))(),
            (async () => this.people = await this._httpService.get(`people`))(),
            (async () => this.instances = await this._httpService.get(`companies/${this.site.companyId}/instances`))(),
            (async () => this.languages = await this._httpService.get(`companies/languages`))(),
            (async () => this.sites = await this._httpService.get(`companies/${this.site.companyId}/sites`))(),
            (async () => this.headsetAccessories = await this._httpService.get(`companies/headset-accessories`))(),
            this.getWaltVersions(),
        ]);

        await this.updateWaltWifiInfo();

        this.siteForm = this.buildForm();
        this.loading = false;
    }

    async getWaltVersions() {
        const versions: VersionData[] = await this._httpService.get('administration/versions');
        this.waltOta = versions.find(v => v.id === 'walt-ota-s');
        this.waltApk = versions.find(v => v.id === 'walt');
        this.currentWaltVersion = isVersionNewer(this.waltOta.mandatory, this.waltApk.mandatory) ? this.waltApk.mandatory : this.waltOta.mandatory;
        this.waltVersions = uniq([
            ...this.waltOta.list.map(v => v.key),
            ...this.waltApk.list.map(v => v.key),
        ])
            .filter(v => isVersionNewer(this.currentWaltVersion, v))
            .sort((a, b) => isVersionNewer(a, b) ? 1 : -1);
        this.waltVersions.unshift('');
    }

    handleShippingAddressChange() {
        this.addressSearch.textSearch(this.siteForm.get('shippingAddress').value);
    }

    handleAddressChange(place: google.maps.places.PlaceResult) {
        this.siteForm.get('address').setValue(place.formatted_address);
        this.siteForm.get('location').setValue([place.geometry.location.lng(), place.geometry.location.lat()]);
    }

    getSiteAddress(site: Site): string {
        if (site.id === 0) return '';
        return site.address ?? '';
    }

    getShippingAddress(site: Site): string {
        if (site.id === 0) return '';
        return site.shippingAddress ?? site.address ?? '';
    }

    validateName() {
        this.siteForm.get('name').updateValueAndValidity();
    }

    existingNamesValidator(sites: Site[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const facilityNames = sites
                .filter(x => x.id !== this.site?.id)
                .filter(x => x.instanceId === this.siteForm?.get('instanceId')?.value?.id)
                .map(x => x.name.toLowerCase());
            const existing = facilityNames.includes(control.value?.toLowerCase());
            return existing ? { siteNameExisting: true } : null;
        };
    }

    buildForm() {
        let waltVersion = this.currentWaltVersion;
        waltVersion = isVersionNewer(waltVersion, this.site?.overrideWaltApk ?? '') ? this.site?.overrideWaltApk : waltVersion;
        waltVersion = isVersionNewer(waltVersion, this.site?.overrideWaltOta ?? '') ? this.site?.overrideWaltOta : waltVersion;
        const formGroup = this._formBuilder.group({
            name: this._formBuilder.control(this.site?.name, { validators: [Validators.required, this.existingNamesValidator(this.sites).bind(this)], updateOn: 'blur' }),
            contactPersonId: this._formBuilder.control(this.site?.contactPersonId, [Validators.required]),
            instanceId: this._formBuilder.control(this.site?.instanceId, [Validators.required]),
            languageId: this._formBuilder.control(this.site?.languageId, [Validators.required]),
            address: this._formBuilder.control(this.getSiteAddress(this.site), [Validators.required]),
            shippingAddress: this._formBuilder.control(this.getShippingAddress(this.site), [Validators.required]),
            location: this._formBuilder.control([]),
            overrideWaltVersion: this._formBuilder.control(waltVersion),
        });

        this.selectedWaltAccessories = this.site.id === 0 ? [] : this.site?.waltAccessories ?? [];
        return formGroup;
    }

    generateSite(): void {
        this.site.name = this.siteForm.get('name').value;
        this.site.contactPersonId = this.siteForm.get('contactPersonId').value.id;
        this.site.instanceId = this.siteForm.get('instanceId').value.id;
        this.site.languageId = this.siteForm.get('languageId').value.id;
        this.site.address = this.siteForm.get('address').value;
        this.site.location = this.siteForm.get('location').value;
        this.site.shippingAddress = this.siteForm.get('shippingAddress').value;
        this.site.waltAccessories = this.selectedWaltAccessories;

        const waltVersion = this.siteForm.get('overrideWaltVersion').value;
        // lists are sorted in descending order, find the first version not newer than the selected
        this.waltOta.list.sort((a, b) => isVersionNewer(a.key, b.key) ? 1 : -1);
        this.waltApk.list.sort((a, b) => isVersionNewer(a.key, b.key) ? 1 : -1);
        this.site.overrideWaltApk = !waltVersion ? '' : this.waltApk.list.find(v => !isVersionNewer(waltVersion, v.key))?.key ?? '';
        this.site.overrideWaltOta = !waltVersion ? '' : this.waltOta.list.find(v => !isVersionNewer(waltVersion, v.key))?.key ?? '';
    }

    async save() {
        this.saving = true;
        try {
            this.generateSite();
            if (this.site.id === 0) await this._httpService.post('companies/sites', this.site);
            else await this._httpService.put('companies/sites', this.site);
            this.saving = false;
            this._router.navigateByUrl(`/companies/companies/${this.site.companyId}`);
        } catch (e) {
            this._snackBar.open(`Failed to save site ${e.message}`, 'Dismiss', { duration: 10000 });
            console.error('site.component: failed to save site', e);
        } finally {
            this.saving = false;
        }
    }

    async updateWaltWifiInfo() {
        const assetTypes = await this._httpService.get('inventory/assetTypes');
        const waltAssetType = assetTypes?.find(at => at.name.toLowerCase().startsWith('walt'));
        if (waltAssetType) {
            this.walts = await this._httpService.get(`inventory/assetTypes/assets/${waltAssetType.id}/${this.site.id}`);

            const waltsCount = this.walts?.length ?? 0;
            const validWifiDisabledUntilDates = this.walts
                ?.map(walt => walt.wifiDisabledUntil)
                ?.filter(wifiDisabledUntil => !!wifiDisabledUntil)
                ?.map(utcStr => new Date(`${utcStr}Z`))
                ?.filter(utcDate => utcDate.valueOf() > Date.now());
            const waltsWithWifiDisabledCount = validWifiDisabledUntilDates?.length ?? 0;

            if (waltsWithWifiDisabledCount) {
                this.waltWifiDisabledDate = min(validWifiDisabledUntilDates);
                this.waltWifiDisabledDate.setDate(this.waltWifiDisabledDate.getDate() - 1);
            } else {
                this.waltWifiDisabledDate = null;
            }

            this.waltWifiInfo = {
                totalCount: waltsCount,
                enabledCount: waltsCount - waltsWithWifiDisabledCount,
                disabledCount: waltsWithWifiDisabledCount,
            };
        }
    }

    handleEnableDisableWaltWifiClicked(shouldWaltWifisDisable: boolean) {
        this.isEnableDisableWaltWifisModalOpen = true;
        this.shouldWaltWifisDisable = shouldWaltWifisDisable;
    }

    isAccessoryChecked(waltAccessory: WaltAccessoryType): boolean {
        return this.selectedWaltAccessories.includes(waltAccessory);
    }

    toggleWaltAccessorySelection(waltAccessory: WaltAccessoryType): void {
        if (this.selectedWaltAccessories.includes(waltAccessory)) {
            this.selectedWaltAccessories = this.selectedWaltAccessories.filter(x => x !== waltAccessory);
        } else {
            this.checkSingleOneEarSelection(waltAccessory);
            this.selectedWaltAccessories.push(waltAccessory);
        }
    }

    checkSingleOneEarSelection(waltAccessory: WaltAccessoryType): void {
        if (waltAccessory === WaltAccessoryType.VX400 && this.selectedWaltAccessories.includes(WaltAccessoryType.VX400B)) {
            this.selectedWaltAccessories = this.selectedWaltAccessories.filter(x => x !== WaltAccessoryType.VX400B);
        } else if (waltAccessory === WaltAccessoryType.VX400B && this.selectedWaltAccessories.includes(WaltAccessoryType.VX400)) {
            this.selectedWaltAccessories = this.selectedWaltAccessories.filter(x => x !== WaltAccessoryType.VX400);
        }
    }

}
