<div *ngIf="loading">
    <mat-spinner class="mt-3" diameter="20" />
</div>

<div *ngIf="!loading" class="row scrollContainer tab col-md-10" style="margin-bottom: 20px;">
    <div class="col-md-9 mb-9 scrollColumn tab">
        <app-table [dataSource]="dataSource" [displayedColumns]="displayedColumns" [showActions]="false"
            uniqueKey="features" *ngIf="!loading" (rowClick)="rowClick($event)"></app-table>
    </div>
    <div class="col-md-3">
        <app-activity [tab]="true" [id]="instance.id" table="instance" />
    </div>
</div>

<ng-template #updateDialog>
    <h2 matDialogTitle>{{selectedFeature.name}}</h2>
    <p>{{selectedFeature.description}}</p>
    <p>Choose individual sites to enable the feature flag, or use the shortcut for All Sites at the bottom.  A checkmark in the box indicates the selection(s) that will be applied.</p>
    <mat-dialog-content>
        <div *ngFor="let site of sites">
            <mat-checkbox [style.opacity]="allSitesCheckboxValue !== undefined ? 0.5 : 1"
                [indeterminate]="allSitesCheckboxValue !== undefined" color="primary" name="site.name"
                (click)="onToggleIndividualSite()" [(ngModel)]="siteEnablement[site.weavixSiteId]">
                {{site.name}}
            </mat-checkbox>
        </div>
        <br>
        <div>
            <mat-checkbox [style.opacity]="allSitesCheckboxValue === undefined ? 0.5 : 1"
                [indeterminate]="allSitesCheckboxValue === undefined" color="primary" name="all"
                (change)="toggleAllSites()" [(ngModel)]="allSitesCheckboxValue">
                All Sites
            </mat-checkbox>
            <p class="text-muted">Enabling or Disabling "All Sites" will use that setting for all current and future
                sites that may be added.</p>
        </div>
    </mat-dialog-content>
    <hr>
    <div class="d-flex justify-content-between">
        <button mat-raised-button class="demo-button" color="primary" (click)="save()">
            Save
        </button>
        <button mat-raised-button class="demo-button" color="secondary" (click)="useDefaultFeatureValue()">
            Use Feature Default ({{selectedFeature.defaultValue ? 'Enabled' : 'Disabled'}})
        </button>
    </div>
</ng-template>